<template>
	<Dialog :dialog="checkinDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Receive {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="checkinForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="checkin-dialog">
					<v-col md="12">
						<perfect-scrollbar
							:options="{ suppressScrollX: true }"
							class="scroll"
							style="max-height: 60vh; position: static"
						>
							<v-list class="mx-2 mx-md-4 border border-secondary" subheader three-line>
								<v-list-item>
									<v-list-item-avatar v-if="relatedDetails">
										<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
									</v-list-item-avatar>
									<v-list-item-content v-if="relatedDetails">
										<v-list-item-title class="font-size-16">
											<Chip :text="relatedDetails.barcode"></Chip>
										</v-list-item-title>
										<v-list-item-subtitle class="font-size-14">
											<b>{{ relatedDetails.name }}</b>
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action class="margin-auto" v-if="relatedDetails">
										<v-list-item-action-text class="font-size-16"
											><b>Cost</b> - {{ relatedDetails.cost_price_formatted }}
										</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</perfect-scrollbar>
					</v-col>
					<v-col md="3" class="py-0">
						<label :for="`checkout-on-${uniqFieldId}`" class="font-level-1-bold my-2">Issue Date</label>
					</v-col>

					<v-col md="9" class="py-0">
						<p class="font-level-1-bold my-2">{{ formatDate(checkin.checkout_on) }}</p>
					</v-col>
					<v-col md="3" class="py-0">
						<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3">Approval Required</label>
					</v-col>
					<v-col md="9" class="py-0">
						<RadioInput
							row
							hide-details
							v-model="checkin.aprrove_transfer"
							:disabled="pageLoading"
							:id="`service-performed-by-${uniqFieldId}`"
							:items="approvalIteam"
						></RadioInput>
					</v-col>
					<v-col md="3" class="my-auto py-0">
						<label :for="`checkin-on-${uniqFieldId}`" class="btx-label mt-3 required">Receive Date</label>
					</v-col>
					<v-col md="9" class="py-0">
						<DateTimePicker
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`checkin-on-${uniqFieldId}`"
							required
							placeholder="Receive Date"
							v-model="checkin.checkin_on"
						></DateTimePicker>
					</v-col>
					<template v-if="false">
						<v-col md="3" class="my-auto py-0">
							<label :for="`location-${uniqFieldId}`" class="btx-label mt-3">Location</label>
						</v-col>
						<v-col md="9" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="locationList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`location-${uniqFieldId}`"
								placeholder="Location"
								v-model="checkin.location"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageLocation()"
							></AutoCompleteInput>
						</v-col>
					</template>
					<template v-if="type == 'Asset Stock'">
						<v-col md="3" class="my-auto py-0">
							<label :for="`member-${uniqFieldId}`" class="btx-label mt-3 required"
								>Quantity 
								<TooltipQuestion>
									<template v-slot:text
										>Total Issue Quantity - {{ checkoutDetails.quantity }} <br />
										Remaining Quantity for Receiveaa - {{ checkoutDetails.remaining_quantity }}</template
									>
								</TooltipQuestion></label
							>
						</v-col>
						<v-col md="9" class="py-0">
							<NumberInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`checkout-duration-${uniqFieldId}`"
								placeholder="Quantity"
								:rules="[vrules.required(checkin.quantity, 'Group')]"
								:class="{
									required: !checkin.quantity,
								}"
								v-model="checkin.quantity"
							></NumberInput>
						</v-col>
					</template>
					<v-col md="3" class="py-0">
						<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Comments</label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`comment-${uniqFieldId}`"
							placeholder="Comments"
							v-model="checkin.comment"
						></TextAreaInput>
					</v-col>
					<v-col md="3" class="my-auto py-0">
						<label for="attachment" class="btx-label mt-2">Attachment</label>
					</v-col>
					<v-col md="9" class="py-0">
						<template v-for="(data, index) in btx_attachments">
							<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<v-col md="5" class="py-0">
									<v-file-input
										v-model="data.file"
										placeholder="Click here to select files"
										color="blue darken-4"
										multiple
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-on:change="limitAttachment($event, index)"
									></v-file-input>
								</v-col>
								<v-col md="5" class="py-0">
									<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="btx_attachments.length < 2"
										v-on:click="removeFile(index)"
										class="white--text mt-3"
										depressed
										color="red darken-4"
										tile
										style="min-width: 18px !important; margin-left: -8px"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="btx_attachments.length > 2"
										v-on:click="addMore()"
										class="white--text mt-3"
										depressed
										color="blue darken-4"
										tile
										style="min-width: 18px !important; margin-left: -8px"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
					</v-col>
					<v-col md="3" class="py-0">
						<label :for="`reason-${uniqFieldId}`" class="btx-label mt-3">Reason</label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`reason-${uniqFieldId}`"
							placeholder="Reason"
							v-model="checkin.reason"
						></TextAreaInput>
					</v-col>
					<template v-if="false">
						<v-col md="3" class="py-0 mt-2">
							<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Signature</label>
						</v-col>
						<v-col md="9" class="py-0">
							<div class="signature-pad" id="signature-pad">
								<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
									<canvas ref="cc_signature"></canvas>
								</div>
							</div>
						</v-col>
					</template>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				v-if="false"
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Receive
			</v-btn>
			<template v-if="cartUuid">
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateCart()"
				>
					Update Cart
				</v-btn>
			</template>
			<template v-else>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="addToCart()"
				>
					Add To Cart
				</v-btn>
			</template>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import { EventBus } from "@/core/event-bus/event.bus";
import SignaturePad from "signature_pad";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import { CART_COUNT } from "@/core/services/store/config.module";
import store from "@/core/services/store";
import NumberInput from "@/view/components/NumberInput";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { UploadFiles } from "@/core/lib/upload.lib";
import RadioInput from "@/view/components/RadioInput";
import TextInput from "@/view/components/TextInput";
import ObjectPath from "object-path";

export default {
	name: "checkin-template",
	title: "Receive Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		checkinDialog: {
			type: Boolean,
			default: false,
		},
		checkoutUuid: {
			type: String,
			default: null,
		},
		cartUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			relatedDetails: {},
			checkoutDetails: {},
			signature: null,
			attachment: [],
			locationList: [],
			approvalIteam: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 0,
				},
			],
			btx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
			checkin: {
				checkout_on: null,
				checkin_on: null,
				description: null,
				reason: null,
				attachment: [],
				member: 0,
				quantity: 0,
				signature: null,
				location: 0,
				aprrove_transfer: 1,
			},
		};
	},
	components: {
		Dialog,
		DateTimePicker,
		TooltipQuestion,
		TextAreaInput,
		NumberInput,
		AutoCompleteInput,
		ImageTemplate,
		Chip,
		RadioInput,
		TextInput,
	},
	watch: {
		checkinDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.init();
					this.pageLoading = true;
				}
			},
		},
		typeUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.getRelatedDetails(this.type, param);
				}
			},
		},
		checkoutUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.getCheckedDetails(param);
				}
			},
		},
	},
	methods: {
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.btx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.btx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		addMore() {
			this.btx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.btx_attachments.splice(index, 1);
			if (this.btx_attachments.length < 1) {
				this.addMore();
			}
		},

		manageLocation() {
			console.log("manageLocation");
		},
		getCheckedDetails(checkoutUuid) {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`${this.endpoint}/${this.typeUuid}/checkout/${checkoutUuid}`)
				.then(({ data }) => {
					this.checkoutDetails = data;
					this.checkin.checkout_on = data?.reservation_relation?.reserve_from;
					// this.checkin.checkout_on = data.return_on
					// ? this.formatDateTimeRaw(data.return_on)
					// :  this.formatDateTimeRaw(data?.reservation_relation?.reserve_from);

					this.checkin.member = data.member;
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getRelatedDetails(type, typeUuid) {
			let url = "";
			if (type == "Asset") {
				url = `asset/${typeUuid}`;
			} else {
				url = `asset-stock/${typeUuid}`;
			}

			this.pageLoading = true;
			ApiService.get(url)
				.then(({ data }) => {
					this.relatedDetails = data;
					if (type != "Asset") {
						this.relatedDetails.cost_price_formatted = data.price_add_stock_formatted;
					}
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			if (this.cartUuid) {
				this.getCartCheckedIn();
			}
			const today = new Date();
			this.checkin = new Object({
				checkout_on: this.formatDateTimeRaw(today),
				checkin_on: this.formatDateTimeRaw(today),
				aprrove_transfer: 1,
				description: null,
				location: 0,
			});

			// this.initSignature();
		},
		getCartCheckedIn() {
			this.pageLoading = true;
			ApiService.get(`cart/${this.cartUuid}`)
				.then(({ data }) => {
					this.checkin = new Object({
						checkout_on: this.formatDateTimeRaw(data.reserve_from),
						checkin_on: this.formatDateTimeRaw(data.reserve_to),
						member: data.member,
						aprrove_transfer: data.aprrove_transfer,
						quantity: data.quantity,
						comment: data.description,
					});
					this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";
					this.checkoutUuid = data.checkout.uuid;

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		initSignature() {
			const _this = this;
			_this.$nextTick(() => {
				let ccanvas = _this.$refs["cc_signature"];
				let ccanvasDiv = _this.$refs["signature_div"];
				if (ccanvasDiv) {
					ccanvas.setAttribute("width", 500);
					ccanvasDiv.setAttribute("style", "width:" + "500px");
					_this.signature = new SignaturePad(ccanvas);
				}
			});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.checkinForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.checkinForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			this.checkin.signature = null; //this.signature.toDataURL();
			ApiService.patch(
				`${this.endpoint}/${this.typeUuid}/checkout/${this.checkoutUuid}/checkin`,
				this.checkin
			)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-reservation", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		addToCart(replace = false) {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.checkinForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (this.checkin.quantity > this.checkoutDetails.remaining_quantity) {
				// alert(this.checkin.quantity > this.checkoutDetails.remaining_quantity);
				// _this.$store.commit(SET_ERROR, [`Total Remaing Quantity is ${this.checkoutDetails.remaining_quantity}`]);
				_this.$store.commit(SET_ERROR, [					
					{
						model: true,
						message: `Total Remaing Quantity is ${this.checkoutDetails.remaining_quantity} `,
					},
				]);
				return false;
			}

			if (!_this.$refs.checkinForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			const cartData = {
				reserve_from: this.checkin.checkout_on,
				reserve_to: this.checkin.checkin_on,
				aprrove_transfer: this.checkin.aprrove_transfer,
				member: this.checkin.member,
				attachment: this.btx_attachments,
				reason: this.checkin.reason,
				quantity: this.checkin.quantity,
				comment: this.checkin.comment,
				signature: null, //this.signature.toDataURL(),
				replace: replace,
				related_type: this.type,
				indefinitely: false,
				checkout: this.checkoutUuid,
			};
			ApiService.post(`cart/${this.typeUuid}/checkin`, cartData)
				.then(({ data }) => {
					if (data && data.exists) {
						this.$emit("close", true);
						this.$swal({
							title: "Another Staff details exist in cart, Do you want to replace it?",
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Replace",
							confirmButtonClass: "bg-danger",
						}).then((result) => {
							if (result.isConfirmed) {
								this.addToCart(true);
							}
						});
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${this.type} has been successfully added to cart.` },
						]);
						this.$emit("success");
						EventBus.$emit("reload:asset-reservation", true);
						store.dispatch(CART_COUNT);
					}
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		updateCart(replace = false) {
			const _this = this;

			if (_this.$refs["checkinForm"]) {
				const formErrors = _this.validateForm(_this.$refs.checkinForm);

				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (this.checkin.quantity > this.checkoutDetails.remaining_quantity) {
					_this.$store.commit(SET_ERROR, [
						`Total Remaing Quantity is ${this.checkoutDetails.remaining_quantity}`,
					]);
					return false;
				}

				if (!_this.$refs.checkinForm.validate()) {
					return false;
				}
			}

			_this.pageLoading = true;

			const cartData = {
				reserve_from: _this.checkin.checkout_on,
				reserve_to: _this.checkin.checkin_on,
				aprrove_transfer: _this.checkin.aprrove_transfer,
				member: _this.checkin.member,
				attachment: _this.checkin.attachment,
				reason: _this.checkin.reason,
				quantity: _this.checkin.quantity,
				comment: _this.checkin.comment,
				signature: null, //_this.signature.toDataURL(),
				replace: replace,
				related_type: _this.type,
				indefinitely: false,
				checkout: _this.checkoutUuid,
			};
			ApiService.put(`cart/${_this.typeUuid}/checkin/${_this.cartUuid}`, cartData)
				.then(({ data }) => {
					if (data && data.exists) {
						_this.$emit("close", true);
						_this
							.$swal({
								title: "Another member details exist in cart, Do you want to replace it?",
								icon: "question",
								showCancelButton: true,
								confirmButtonText: "Replace",
								confirmButtonClass: "bg-danger",
							})
							.then((result) => {
								if (result.isConfirmed) {
									_this.updateCart(true);
								}
							});
					} else {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${_this.type} has been successfully added to cart.` },
						]);
						_this.$emit("success", true);
						EventBus.$emit("reload:cart", true);
						EventBus.$emit("reload:asset-reservation", true);
						store.dispatch(CART_COUNT);
					}
				})
				.catch((error) => {
					_this.$emit("error", error);
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		// this.initSignature();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
